import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { auth } from './config/firebaseConfig'; 
import { fetchReferredUsersFromFirestore, fetchUserReferralCodeFromFirestore, updatePermanentReferralCodeForUserInFirestore } from './utilityFunctions/firestoreUtilities';
import { v4 as uuidv4 } from 'uuid'; 


function Referrals() {

    // const authUser = useContext(AuthContext);
    const { authUser } = useContext(AuthContext);
    const [usersReferralCode, setUsersReferralCode] = useState(null);
    const [referredUsers, setReferredUsers] = useState([]);


    useEffect(() => {
        const fetchReferralData = async () => {
            if (auth.currentUser) {
                const code = await fetchUserReferralCodeFromFirestore(authUser);
                setUsersReferralCode(code);
                if (code) {
                    const users = await fetchReferredUsersFromFirestore(authUser, code);
                    setReferredUsers(users);
                }
            }
        };

        fetchReferralData();
    }, [authUser]);
    const createReferralCodeForUser = async () => {
        const newReferralCode = uuidv4();
        await updatePermanentReferralCodeForUserInFirestore(auth.currentUser.uid, newReferralCode);
        const fetchedCode = await fetchUserReferralCodeFromFirestore(authUser);
        setUsersReferralCode(fetchedCode);
    };

  
    return (
        <div className="flex min-h-full flex-col items-center justify-center px-6 py-12 lg:px-8">
            {/* Logo at the top left */}
            <div className="self-start">
                <button onClick={() => window.location.href = '/'} className="w-30 h-12 p-2">
                Return Home
                </button>
            </div>
            {/* Content */}
            <div className='mt-20' style={{ width: '40vw' }}>
                <p className="text-3xl font-bold">Refer a friend, and get $100 each!</p>
                <div className="w-full text-left mt-4">                    
                    <p className='text-center '>
                        Refer a friend and earn $100 in credit towards your subscription. Your friend will receive $100 in credit towards their subscription.
                    </p>
                    <p className="text-gray-500 italic mt-6 mb-4">                        
                        
                    </p>
                </div>
                
                <div className="mt-14">
                    <p className="text-xl font-semibold">Your Referral Code</p>
                    <p className="text-gray-700 mt-2">{usersReferralCode ? usersReferralCode : "No referral code generated yet."}</p>
                </div>
                
                {authUser && usersReferralCode === null && (
                    <div className="my-4">
                        <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={createReferralCodeForUser}
                        >
                        Generate Referral Code
                        </button>
                    </div>
                )}

                <div className="w-full mt-16">
                    <p className="text-xl font-semibold">Your Referrals</p>
                    <table className="min-w-full divide-y divide-gray-200 mt-6">
                        <thead className="bg-gray-50">
                            <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Invited Users
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date Joined
                            </th>
                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Credits Earned
                            </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {referredUsers.map((user) => (
                                <tr key={user.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
                                        {user.email}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                        {new Date(user.signUpDate.seconds * 1000).toLocaleDateString()}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                                        $100
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <p className='text-gray-500 italic text-sm mt-10' >Free credits will be granted in December 2024 and can be used starting January 2025, when all customers will transition to paid plans starting at $100 a month.</p>
                </div>
            </div>
        </div>
      
  );
}

export default Referrals;