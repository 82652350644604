import {  useContext } from 'react';
import { deleteFileFromOpenAiVectorStore, uploadFileToOpenAI, addFileToAssistantVectorStore } from '../../utilityFunctions/openAiUtilities';
import { markFileAsDeletedInFirestore, uploadFileToFirebaseStorage, saveOpenAiFileIdToFirestore, saveFileMetadataToFirestore } from '../../utilityFunctions/firestoreUtilities';
import { generateUniqueFileId } from '../../utilityFunctions/uxUtilities';
import { AuthContext } from '../../AuthContext';

const KnowledgeBaseModal = ({ 
        setShowKnowledgeBaseModal, 
        assistantFiles, 
        setAssistantFiles,
        assistantVectorStoreId,
        fetchAssistantFiles,
        setShowLoadingModal
    }) => {


    const { authUser } = useContext(AuthContext);

    async function deleteAssistantFile(fileId) {
        if (window.confirm("Are you sure you want to permanently delete this file?")) {
            try {
                await deleteFileFromOpenAiVectorStore(assistantVectorStoreId, fileId);
                await markFileAsDeletedInFirestore(fileId);
                setAssistantFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
            } catch (error) {
                console.error("Failed to delete assistant file: ", error);
            }
        }
    }

    const handleUserFileUpload = async (file) => { 
        if (!file) {
            alert("No file selected for upload.");
            console.error("No file selected for upload.");
            return;
        }
        
        try {
            console.log("handleUserFileUpload proceeding, file detected")
            setShowLoadingModal(true)
            const uniqueFileId = generateUniqueFileId();
            const downloadURL = await uploadFileToFirebaseStorage(file, uniqueFileId);
            const firestoreRef = await saveFileMetadataToFirestore(uniqueFileId, file.name, downloadURL, authUser.uid);
            const openaiFileId = await uploadFileToOpenAI(file);
            await saveOpenAiFileIdToFirestore(firestoreRef, openaiFileId);
            await addFileToAssistantVectorStore(assistantVectorStoreId, openaiFileId);
    
            fetchAssistantFiles();
            setShowLoadingModal(false)
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };  

    return (
        <div 
            className="modal-overlay" 
            style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}
            onClick={() => setShowKnowledgeBaseModal(false)}
        >
            <div 
                className="modal p-8" 
                style={{ cursor: 'default', width: '80vw', height: '80vh', backgroundColor: 'white', borderRadius: '10px' }}
                onClick={(e) => e.stopPropagation()} // Prevent click event from propagating to the overlay
            >
                <div className="flex justify-end items-center space-x-4">
                    <input 
                        type="file" 
                        id="fileInput" 
                        accept=".doc, .docx, .md, .pdf, .txt"
                        style={{ display: 'none' }} 
                        onChange={async (event) => {
                            const file = event.target.files[0];
                            if (file) {
                                await handleUserFileUpload(file); 
                            }
                        }} 
                    />
                    <button 
                        onClick={() => document.getElementById('fileInput').click()} 
                        className="text-white bg-indigo-500 hover:bg-blue-700 text-sm font-bold py-2 px-4 rounded"
                    >
                        Upload
                    </button>
                    <button onClick={() => setShowKnowledgeBaseModal(false)} className="text-white bg-gray-500 hover:bg-gray-700 text-sm font-bold py-2 px-4 rounded">
                        Close
                    </button>
                </div>
                <div className="flex justify-center items-center mb-2">
                    <p className='text-xl'>Daisy's Knowledge Base</p>
                </div>
                <p className="text-sm ml-6 mb-12 text-gray-700 italic">Daisy will use these documents to learn about your nonprofit and generate personalized responses</p>
                <table className="min-w-full text-left divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                File Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Created At
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {assistantFiles.map((file, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {file.downloadURL ? (
                                        <a href={file.downloadURL} target="_blank" rel="noopener noreferrer" className="text-sm text-indigo-600 hover:underline">
                                            {file.fileName}
                                        </a>
                                    ) : (
                                        <div className="text-sm text-gray-900">{file.fileName}</div>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-500">{new Date(file.created_at * 1000).toLocaleString()}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap  text-sm ">
                                    <button onClick={() => deleteAssistantFile(file.id)} className="text-red-300 hover:text-red-600">Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
       </div>
    );
};

export default KnowledgeBaseModal;