import React from 'react';
import loadingGif from '../../assets/loading.gif'; 

const MagicFirstDraftLoadingModal = ({ 
}) => {
            
    return (
        <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 2147483647 }}>        
            <div>                
                <img src={loadingGif} alt="Loading..." />
                <p className='font-bold italic text-white'>LOADING...</p>
            </div>
        </div>
    );
};

export default MagicFirstDraftLoadingModal;