import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import AppComponents from './LegacyComponents/AppComponents';
import { AuthProvider } from './AuthContext';
import posthog from 'posthog-js'
import Login from './ApplicationComponents/AuthComponents/Login';
import Signup from './ApplicationComponents/AuthComponents/Signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Referrals from './Referrals';
import DiscoverGrants from './LegacyComponents/DiscoverGrants'
import CreateGrantOpportunities from './LegacyComponents/CreateGrantOpportunities'
import Home from './ApplicationComponents/Home'

function App() {    

  return (
    <div className="App"> 
      <AuthProvider>
      <Router>
        <Routes>        
          <Route path="/" element={<Home />} />     
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/referrals" element={<Referrals />} />          
          <Route path="/home" element={<Home />} />
          <Route path="/organizations/:ein" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </AuthProvider>       
    </div>
  );
}

export default App;