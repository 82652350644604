import React, { useState, useEffect } from 'react';
import AddContentModal from './AddContentModal'; 
import { collection, getDocs, deleteDoc, query, where, doc } from "firebase/firestore";
import db from '../../config/firebaseConfig';
import ProposalTextComponent from './ProposalTextComponent';

const ProposalEditor = ({     
    currentProposal,
    setCurrentProposal,
    setCurrentView,
    assistantFiles,
    setShowLoadingModal,
    assistantId
}) => {
    
    const [proposalTextComponents, setProposalTextComponents] = useState([])
    const [openAddContentModal, setOpenAddContentModal] = useState(false);

    const fetchProposalComponents = async () => {        
        try {
            console.log("Fetching grant proposal components for proposal ", currentProposal.id)
            const q = query(collection(db, "grantProposalComponents"), where("proposalId", "==", currentProposal.id));
            const querySnapshot = await getDocs(q);
            const fetchedProposalComponents = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id; // Add the document ID to the data
                console.log(data);
                fetchedProposalComponents.push(data);
            });            
            fetchedProposalComponents.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds); // Sort components by createdAt timestamp
            setProposalTextComponents(fetchedProposalComponents);
        } catch (error) {
            console.error("Error fetching proposal components: ", error);
        }
    };

    useEffect(() => {
        fetchProposalComponents();
    }, [currentProposal.id]);

    const homeButtonClicked = () => {
        setCurrentView('proposalsDashboard');
        setCurrentProposal(null);
    } 

    const closeAddContentModal = async () => {
        await fetchProposalComponents();
        setOpenAddContentModal(false);        
    };

    const handleProposalComponentDeletion = (componentId) => {
        if (!window.confirm('Are you sure you want to delete this component?')) return;
        deleteGrantProposalComponentInFirestore(componentId);
        fetchProposalComponents();
    }
    
    const deleteGrantProposalComponentInFirestore = async (componentId) => {
        try {
            console.log("Deleting grant proposal component in Firestore")
            const docRef = doc(db, "grantProposalComponents", componentId);
            await deleteDoc(docRef);
            console.log("Component deleted successfully. ID: ", componentId);
        } catch (error) {
            console.error("Error deleting component: ", error);
        }
    };

    return (
        <div className="p-12 h-screen">
            <div className="">
                <div>
                    <button className='block text-sm text-gray-500 text-left mb-4' onClick={homeButtonClicked}>Return Home</button>
                    <div className="flex justify-between items-center w-full mt-6">
                        <p className="text-3xl font-bold text-left">{currentProposal?.name}</p>   
                        <button onClick={() => setOpenAddContentModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Add Component</button>
                    </div>                    
                </div>
            </div>

            {proposalTextComponents && proposalTextComponents.map((component) => (
                <ProposalTextComponent 
                    component={component} 
                    assistantFiles={assistantFiles}
                    handleProposalComponentDeletion={handleProposalComponentDeletion}
                    setShowLoadingModal={setShowLoadingModal}
                    assistantId={assistantId}                    
                />
            ))}
            
            <button onClick={() => setOpenAddContentModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mb-12">Add Component</button>
            {openAddContentModal && (
                <AddContentModal 
                    closeAddContentModal={closeAddContentModal} 
                    currentProposalId={currentProposal.id} 
                /> 
            )}        
        </div>
    );
}

export default ProposalEditor;