import React, { useState } from 'react';
import './OpportunityAndFunderModal.css';
import OpportunityProfile from './OpportunityProfile';
import OrganizationProfile from './OrganizationProfile';

const OpportunityAndFunderModal = ({
    generateMagicFirstDraft,    
    selectedOpportunity,
    selectedOrganizationEin,
    setSelectedOpportunity,
    setSelectedOrganizationEin,
    setShowLoadingModal,
    setShowOpportunityAndFunderModal,    
}) => {

    const [opportunityModalMode, setOpportunityModalMode] = useState(selectedOpportunity ? "opportunity" : "funder");
    
    return (
        <div className="bg-gray-900 bg-opacity-50" 
             style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
             onClick={() => setShowOpportunityAndFunderModal(false)}
        >
            
            {/* Modal */}
            <div className='text-left bg-white rounded-lg' 
                 style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}>
                
                {/* Header */}                                
                <div className="flex w-full text-center text-gray-500">
                    <button 
                        className={`w-1/2 p-4 ${opportunityModalMode === "opportunity" ? "bg-gray-600 text-white font-bold" : "bg-gray-200"}`}
                        onClick={() => setOpportunityModalMode("opportunity")}>
                        Grant Opportunity
                    </button>
                    <button 
                        className={`w-1/2 p-4 ${opportunityModalMode === "funder" ? "bg-gray-600 text-white font-bold" : "bg-gray-200"}`}
                        onClick={() => setOpportunityModalMode("funder")}>
                        Funder
                    </button>
                </div>
                
                {/* Body */}
                {opportunityModalMode === "opportunity" ? (
                    <OpportunityProfile 
                        selectedOpportunity={selectedOpportunity} 
                        generateMagicFirstDraft={generateMagicFirstDraft} 
                    />
                ) : (
                    <OrganizationProfile 
                        selectedOrganizationEin={selectedOrganizationEin} 
                        setSelectedOpportunity={setSelectedOpportunity}
                        setSelectedOrganizationEin={setSelectedOrganizationEin}
                        setShowLoadingModal={setShowLoadingModal}
                        setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
                    />
                )}
            </div>
        </div>
    );
};

export default OpportunityAndFunderModal;
