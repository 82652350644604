import React, { useContext } from 'react';
import { createVectorStore, createAssistantInOpenAi, uploadFileToOpenAI, addFileToAssistantVectorStore } from '../../utilityFunctions/openAiUtilities';
import { saveAssistantToFirestore, createClientOrganizationInFirestore, createProgramInFirestore } from '../../utilityFunctions/firestoreUtilities';
import { generateUniqueFileId } from '../../utilityFunctions/uxUtilities';
import { AuthContext } from '../../AuthContext';




function CreateAssistantForm({ 
    fetchAssistantFiles,
    fetchClientOrganizationsBelongedTo,
    fetchProgramsForOrganization,
    setAssistantId, 
    setassistantVectorStoreId, 
    setShowScrapingLoadingModal 
}) {

    const { authUser } = useContext(AuthContext);
    const SCRAPINGANT_API_KEY = '88b4850dd26f45cf936f63846d3db5e2'; 

    async function extractWebsiteDetails(url) {
        const apiUrl = `https://api.scrapingant.com/v2/extract?url=${encodeURIComponent(url)}&extract_properties=overview,mission,populationsServed,areasServed&x-api-key=${SCRAPINGANT_API_KEY}`;
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error('Failed to fetch website details');
        }
        return response.json();
    }
    
    const handleCreateAssistantButtonPressed = async (event) => {
        event.preventDefault();
        const websiteLink = event.target.websiteLink.value;
        const organizationName = event.target.organizationName.value;

        if (!websiteLink || !organizationName) {
            alert('Both fields are required.');
            return;
        }

        try {
            console.log("Fetching website details with ScrapingAnt")
            setShowScrapingLoadingModal(true)
            let websiteDetails;
            try {
                websiteDetails = await extractWebsiteDetails(websiteLink);
                console.log('Website Details:', websiteDetails);
            } catch (error) {
                console.error("Failed to fetch website details:", error);
                websiteDetails = null;
            }

            const createdVectorStore = await createVectorStore();
            const myAssistant = await createAssistantInOpenAi(organizationName, createdVectorStore.id);            
            const createdClientOrganizationRef = await createClientOrganizationInFirestore(organizationName, authUser.uid);
            const createdClientOrganizationId = createdClientOrganizationRef.id; 
            await saveAssistantToFirestore(myAssistant, createdVectorStore.id, websiteLink, createdClientOrganizationId);

            // const programName = organizationName + " Parent Organization";
            const programName = "Parent Organization";

            await createProgramInFirestore(websiteDetails, authUser.uid, createdClientOrganizationId, programName)


            setShowScrapingLoadingModal(false)
            setAssistantId(myAssistant.id);
            // setAssistantName(myAssistant.name);
            setassistantVectorStoreId(createdVectorStore.id);
            fetchClientOrganizationsBelongedTo()
            fetchProgramsForOrganization(createdClientOrganizationId)
            
        } catch (error) {
            console.error("Error in creating or saving assistant:", error);
        }
    };

    return (
        <div className='AppInterface' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '93vh' }}>                                    
            <form style={{ textAlign: 'center' }} onSubmit={handleCreateAssistantButtonPressed}>
                <h1 className="text-3xl font-bold">Tell us about your nonprofit!</h1>
                <p className="text-md mt-3 mb-10 text-gray-600">To get started, complete the following forms:</p>
                <div>
                    <label htmlFor="organizationName" className="block mb-2 text-sm font-medium text-gray-900">Organization Name</label>                        
                    <input type="text" id="organizationName" name="organizationName" placeholder='Public Housing Community Fund' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                </div>
                <div>
                    <label htmlFor="websiteLink" className="block mb-2 mt-10 text-sm font-medium text-gray-900">Organization Website</label>
                    <input 
                        type="url" 
                        id="websiteLink" 
                        name="websiteLink" 
                        placeholder='https://example.org' 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                        pattern="https?://.+"
                    />
                    <small className="text-gray-600">URL must start with http:// or https://</small>
                </div>

                <button
                    type="submit"
                    className="mt-12 bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-2 px-4 rounded"
                >
                    Create
                </button>
            </form>
        </div>
    );
}

export default CreateAssistantForm;