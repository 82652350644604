import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import db, { auth } from '../../config/firebaseConfig';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { stripHtmlTags } from '../../utilityFunctions/uxUtilities';
import PaywallComponent from '../HelperComponents/PaywallComponent';

const ProgramDashboard = ({
    currentProgram,
    membershipDetails,
    setProgramToModify,
    setShowCreateProgramModal,
    setShowOpportunityAndFunderModal,
    setSelectedOpportunity
}) => {

    const [opportunities, setOpportunities] = useState([]);
    const [leadScores, setLeadScores] = useState([]);
    const [rowData, setRowData] = useState([]);

    useEffect(() => {
        // fetchProcessedGrantOpportunititesFromFirestore();
        fetchOpportunityLeadScoresForProgram(currentProgram.id);
    }, [currentProgram]);
    
    useEffect(() => {
        fetchProcessedGrantOpportunititesFromFirestore();
        // fetchOpportunityLeadScoresForProgram(currentProgram.id);
    }, []);

    useEffect(() => {
        console.log("OPP from UE: ", opportunities)
        console.log("LS from UE: ", leadScores)
        if (opportunities.length && leadScores.length) {
            const combinedData = opportunities.map(opportunity => {
                const leadScore = leadScores.find(score => score.opportunityId === opportunity.id);
                return { 
                    ...opportunity, 
                    leadScore: leadScore ? leadScore.categorization : null,
                    leadScoreResponse: leadScore ? leadScore.response : null,
                    leadScoreCreationTimestamp: leadScore ? leadScore.createdAt : null 
                };
            });
            console.log("CDCD", combinedData)
            setRowData(combinedData);
        }
    }, [opportunities, leadScores]);

    // Component Methods

    const handleRowClick = (data) => {
        setSelectedOpportunity(data)
        setShowOpportunityAndFunderModal(true)
    };

    const handleManageProgramButtonClicked = () => {
        setProgramToModify(currentProgram)
        setShowCreateProgramModal(true);        
    };

    // const fetchUserOpportunityLeadScores = async () => {
    //     console.log("Fetching user's opportunity lead scores from Firestore.")
    //     try {
    //         const q = query(collection(db, "opportunityLeadScores"), where("userId", "==", auth.currentUser.uid));
    //         const querySnapshot = await getDocs(q);
    //         const leadScoresArray = [];
    //         querySnapshot.forEach(doc => {
    //             leadScoresArray.push({ id: doc.id, ...doc.data() });
    //         });
    //         console.log(leadScoresArray)
    //         setLeadScores(leadScoresArray);
    //     } catch (error) {
    //         console.error("Error fetching opportunities:", error);
    //     }
    // };

    const fetchOpportunityLeadScoresForProgram = async (programId) => {
        console.log("Fetching program's opportunity lead scores from Firestore.")
        try {
            const q = query(collection(db, "opportunityLeadScores"), where("programId", "==", programId));
            const querySnapshot = await getDocs(q);
            const leadScoresArray = [];
            querySnapshot.forEach(doc => {
                leadScoresArray.push({ id: doc.id, ...doc.data() });
            });
            console.log(leadScoresArray)
            setLeadScores(leadScoresArray);
        } catch (error) {
            console.error("Error fetching opportunities:", error);
        }
    };

    const fetchProcessedGrantOpportunititesFromFirestore = async () => {
        console.log("Fetching enriched grant opportunities from Firestore.")
        try {
            const q = query(collection(db, "grantOpportunities"), where("status", "==", "enriched"));
            const querySnapshot = await getDocs(q);
            const opportunitiesArray = [];
            querySnapshot.forEach(doc => {
                opportunitiesArray.push({ id: doc.id, ...doc.data() });
            });
            console.log(opportunitiesArray)
            setOpportunities(opportunitiesArray);
        } catch (error) {
            console.error("Error fetching opportunities:", error);
        }
    };

    const columnDefs = [
        { 
            headerName: "Discovered", 
            field: "leadScoreCreationTimestamp", 
            filter: 'agDateColumnFilter',
            valueFormatter: ({ value }) => {
                if (value && value.toDate) {
                    return value.toDate().toLocaleDateString();
                }
                return 'N/A';
            }
        },
        { 
            headerName: "Grant Name", 
            field: "grantName", 
            filter: 'agTextColumnFilter',
        },
        { 
            headerName: "Lead Score", 
            field: "leadScore", 
            filter: 'agTextColumnFilter', 
            valueFormatter: ({ value }) => value !== null ? value : 'N/A'
        },
        { 
            headerName: "Score Notes", 
            field: "leadScoreResponse", 
            filter: 'agTextColumnFilter', 
            // valueFormatter: ({ value }) => value !== null ? value : 'N/A'
        },

        { 
            headerName: "Overview", 
            field: "grantOverview", 
            filter: 'agTextColumnFilter',
            autoHeight: true,
            cellRenderer: props => stripHtmlTags(props.value)
        },
        { 
            headerName: "Grant Size Min", 
            field: "grantSizeMin", 
            filter: 'agNumberColumnFilter', 
            valueFormatter: ({ value }) => {
                if (value === null || value === undefined || isNaN(value)) {
                    return 'N/A';
                }
                value = parseInt(value, 10);
                if (isNaN(value)) {
                    return 'N/A';
                }
                return `$${value.toLocaleString()}`;
            },
            valueParser: ({ newValue }) => {
                return parseInt(newValue, 10) || 0;
            }
        },
        { 
            headerName: "Grant Size Max", 
            field: "grantSizeMax", 
            filter: 'agNumberColumnFilter', 
            valueFormatter: ({ value }) => {
                if (value === null || value === undefined || isNaN(value)) {
                    return 'N/A';
                }
                value = parseInt(value, 10);
                if (isNaN(value)) {
                    return 'N/A';
                }
                return `$${value.toLocaleString()}`;
            },
            valueParser: ({ newValue }) => {
                return parseInt(newValue, 10) || 0;
            }
        },
        { 
            headerName: 'Website', 
            field: 'url', 
            cellRenderer: props => {
                let website = props.value;
                if (typeof website === 'string' && !website.startsWith('https://')) {
                    website = `https://${website}`;
                }
                return <a className='text-blue-500' href={website} target="_blank">{props.value}</a>;
            }
        },


    ];
    

    return (
        <div className='p-12'>
            {/* <p></p> */}
            <div className="flex mt-10 space-x-8">
                {currentProgram && <p className="text-3xl font-bold text-left">{currentProgram.programName}</p>}
                <button className='bg-gray-200 p-2 text-sm rounded-lg' onClick={handleManageProgramButtonClicked}>Manage</button>
            </div>
            {/* <p className='italic text-left text-gray-500'>Active grant opportunities by private foundations looking to fund nonprofits</p> */}
            
            <p className='text-left mt-8 text-sm font-bold text-gray-500'>PROGRAM DETAILS</p>
            <div className='bg-gray-200 rounded text-left p-3 text-sm my-2'>
                <p><span className="text-gray-500">Areas Served:</span> {stripHtmlTags(currentProgram.areasServed)}</p>
                <p><span className="text-gray-500">Mission:</span> {stripHtmlTags(currentProgram.mission)}</p>
                <p><span className="text-gray-500">Overview:</span> {stripHtmlTags(currentProgram.overview)}</p>
                <p><span className="text-gray-500">Population Served:</span> {stripHtmlTags(currentProgram.populationsServed)}</p>
            </div>

            {membershipDetails ? (
                <div className="w-full text-left mt-16 ag-theme-alpine" style={{ width: '100%' }}>
                    <style>
                        {`
                            .ag-theme-alpine .ag-cell {
                                user-select: text;
                            }
                            .ag-theme-alpine .ag-row:hover {
                                cursor: pointer;
                            }
                        `}
                    </style>
                    <p className='text-left mt-8 mb-2 text-sm font-bold text-gray-500'>GRANT OPPORTUNITIES</p>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        onRowClicked={(event) => handleRowClick(event.data)}
                        domLayout='autoHeight'
                    />
                </div>
            ) : (
                <PaywallComponent />
            )}
        </div>
    );
}

export default ProgramDashboard;
