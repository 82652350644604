import React, { useEffect } from 'react';

const OpportunityProfile = ({ 
    selectedOpportunity, 
    generateMagicFirstDraft 
}) => {
    useEffect(() => {
        console.log('Selected Opportunity:', selectedOpportunity);
    }, [selectedOpportunity]);

    return (
        selectedOpportunity ? (
            <div className='p-12'>
                <div className='text-sm'>
                    <div className="ml-6 mt-12 text-3xl font-bold">{selectedOpportunity.grantName}</div>
                    <div className="ml-6 mt-4 mb-12"><a className='text-blue-800' href={selectedOpportunity.url} target="_blank" rel="noopener noreferrer">Visit Website</a></div>
                    
                    {/* Score Div */}
                    <div className='m-6 bg-orange-100 p-6 rounded-lg'>
                        <p className='font-bold text-lg '>{selectedOpportunity.leadScore}</p>
                        <p>{selectedOpportunity.leadScoreResponse}</p>
                    </div>
                    <div className="m-6"><strong>Grant Overview</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.grantOverview }} /></div>
                    <div className="m-6"><strong>Eligibility Criteria:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.eligibilityCriteria }} /></div>
                    <div className="m-6"><strong>Application Requirements:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.applicationRequirements }} /></div>
                    <div className="m-6"><strong>Grant Size Range:</strong><br />${Number(selectedOpportunity.grantSizeMin).toLocaleString('en-US', { minimumFractionDigits: 0 })} - ${Number(selectedOpportunity.grantSizeMax).toLocaleString('en-US', { minimumFractionDigits: 0 })}</div>
                    <div className="m-6"><strong>Important Dates:</strong><br /><span className="richTextContainer" dangerouslySetInnerHTML={{ __html: selectedOpportunity.importantDates }} /></div>
                    <div className="m-6"><strong>Contact Info:</strong><br />{selectedOpportunity.contactInfo}</div>
                </div>
                <div className="m-6">
                    <button className="bg-indigo-500 hover:bg-primary-dark text-white font-bold py-2 px-4 rounded" onClick={() => generateMagicFirstDraft(selectedOpportunity.applicationRequirements, selectedOpportunity.grantName)}>
                        🪄 Generate Magic First Draft
                    </button>
                </div>
                <p className='ml-6 italic text-xs text-gray-500'>
                    Created at: {selectedOpportunity.createdAt ? new Date(selectedOpportunity.createdAt.toDate()).toLocaleDateString() : 'N/A'}. 
                    Last updated: {selectedOpportunity.updatedAt ? new Date(selectedOpportunity.updatedAt.toDate()).toLocaleDateString() : 'N/A'}
                </p>
            </div>
        ) : (
            <div className='p-24 text-center flex items-center justify-center text-gray-500'>
                No opportunity selected
            </div>
        )
    );
};

export default OpportunityProfile;