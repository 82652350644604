import { v4 as uuidv4 } from 'uuid'; 


export const formatDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString();
    }
    return '';
};


export const toAllCaps = (str) => {
    return str.toUpperCase();
};

export const generateUniqueFileId = () => {
    const uniqueId = uuidv4();
    console.log("Generated unique file ID:", uniqueId);
    return uniqueId;
};

// Function to clean and strip text of any formatting
export const cleanText = (text) => {
    // Remove HTML tags
    let cleanedText = text.replace(/<\/?[^>]+(>|$)/g, "");
    // Remove Markdown formatting
    cleanedText = cleanedText.replace(/(\*\*|__|~~|`|>|\[|\]|\(|\)|!|\*|#|\+|-|_|=|~|`|>|<)/g, "");
    // Remove extra whitespace
    cleanedText = cleanedText.replace(/\s\s+/g, ' ').trim();
    return cleanedText;
};

export const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
};