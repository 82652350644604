import React from 'react';

function ManageMembership({
    membershipDetails
}) {
  
    return (
        <div className='flex flex-col justify-center p-12'>
            <p className="text-3xl mt-10 font-bold text-left">Membership Details</p>                 
            <div className='text-left mt-10'>
                <div>{membershipDetails 
                    ? 
                        <div>
                            <p>Current Plan: <span className="text-indigo-500 font-bold">Premium</span></p> 
                            <p>The Premium plan includes unlimited access to our Grant Database and Grant Discovery features.</p>
                            <p className='text-gray-500 italic text-sm mt-6'>To make changes to your subscription, contact Support.</p>
                        </div>                    
                    :  
                        <div>
                            <p>Current Plan: <span className="text-gray-500 font-bold">Free</span></p> 
                            <p>To upgrade, and get access to our grant database functionality, <a className='text-indigo-500 font-bold' href="https://satodadj.gumroad.com/l/rlqzat" target="_blank" rel="noopener noreferrer">upgrade here</a>.</p>
                        </div>
                    }
                </div>
            </div>
        </div>                    
  );
}

export default ManageMembership;