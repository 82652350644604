// Import libraries
import React, { useEffect } from 'react';

// Import utility functions
import { createGrantProposalInFirestore } from '../../utilityFunctions/firestoreUtilities'
import { deleteDoc, doc } from "firebase/firestore";
import db from '../../config/firebaseConfig'; 

const ProposalsDashboard = ({ 
        authUser,
        setCurrentProposal, 
        currentClientOrganization,
        currentProposal,        
        setShowManageProposalModal,
        setCurrentProposalToManage,
        refreshGrantProposals,
        grantProposals,
        setShowMagicFirstDraftModal
    }) => {    

    // When user changes or no document ID currently set, fetch grant proposals for current user
    useEffect(() => {            
        refreshGrantProposals();
    }, [authUser, currentProposal === null]);

    // Main function handle when user requests to delete a grant document
    const handleDeleteGrantProposal = async (selectedProposal) => {
        if (window.confirm("Are you sure you want to delete this grant proposal?")) {            
            try {                                                                
                console.log("Deleting grant proposal in Firestore.");
                await deleteDoc(doc(db, "grantProposals", selectedProposal.id));            
                refreshGrantProposals()
            } catch (error) {
                console.error("Error removing document: ", error);
            }
        }
    };

    const handleEditGrantProposal = (proposal) => {
        setCurrentProposalToManage(proposal)
        setShowManageProposalModal(true) // REFACTOR: can this be removed and just use if currentProposalToManage is set?
        console.log(proposal)
    }

    const handleCreateNewProposal = async () => {
        const grantName = prompt("Enter a name for your new proposal:");
        if (grantName) {
            await createGrantProposalInFirestore(authUser, grantName, currentClientOrganization.id);
            refreshGrantProposals();
        }
    };

    // Main function to handle when user selects a document
    const handleGrantProposalSelect = (proposal) => {
        setCurrentProposal(proposal)
    };    

    return (
        <div className='p-12'>
            <div className="flex justify-between items-center mt-10">
                <p className="text-3xl font-bold text-left">Your Grant Proposals</p>
                <div className="flex space-x-4">
                    <button onClick={handleCreateNewProposal} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                        Create Blank Draft
                    </button>
                    <button onClick={() => setShowMagicFirstDraftModal(true)} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">
                        🪄 Create Magic First Draft
                    </button>
                </div>
            </div>            

            <div className=''>                
                <table className='w-full text-left mt-5 text-sm'>
                    <thead>
                        <tr>
                            <th className='px-2 py-2 text-gray-400 text-xs semibold' style={{ width: '25%' }}>Opportunity Name</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '15%' }}>Updated</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '15%' }}>Created</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '15%' }}>Status</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '15%' }}>Notes</th>
                            <th className='px-4 py-2 text-gray-400 text-xs semibold' style={{ width: '15%' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {grantProposals.map((proposal) => (
                            <tr key={proposal.id} onClick={() => handleGrantProposalSelect(proposal)} className="hover:bg-gray-200" style={{ cursor: 'pointer' }}>
                                <td className='px-2 py-2'>{proposal.name}</td>
                                <td className='px-4 py-2'>{new Date(proposal.updatedAt.seconds * 1000).toLocaleString()}</td>
                                <td className='px-4 py-2'>{new Date(proposal.createdAt.seconds * 1000).toLocaleString()}</td>
                                <td className='px-4 py-2'>{proposal.status}</td>
                                <td className='px-4 py-2'>{proposal.notes}</td>
                                <td className='px-4 py-2 flex'>
                                    <button 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditGrantProposal(proposal);
                                        }} 
                                        className="bg-gray-300 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded"
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteGrantProposal(proposal);
                                        }} 
                                        className="bg-gray-300 hover:bg-red-500 text-white font-bold py-1 px-2 rounded ml-2"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProposalsDashboard;