import React, { useState } from 'react';
import { createGrantProposalComponentInFirestore } from '../../utilityFunctions/firestoreUtilities';

const AddContentModal = ({ 
    closeAddContentModal, 
    currentProposalId  
}) => {
    
    const [proposalRequirement, setProposalRequirement] = useState('');

    const handleSubmit = () => {
        createGrantProposalComponentInFirestore(currentProposalId, proposalRequirement)
        closeAddContentModal()
    };

    return (
        <div className="modal-overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="modal" style={{ cursor: 'default',  width: '80vw', height: 'auto', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                <h2 className='text-xl py-4'>Add Component to Proposal</h2>
                <div className="mt-4 px-24">
                    <label htmlFor="proposalRequirement" className="block text-sm font-medium text-gray-700">
                        Proposal Requirement <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="proposalRequirement"
                            id="proposalRequirement"
                            className="shadow-sm block w-full py-2  border-gray-300 rounded-md"
                            placeholder="Enter application requirement"
                            value={proposalRequirement}
                            onChange={(e) => setProposalRequirement(e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="flex justify-center mt-8">
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                        disabled={!proposalRequirement}
                    >
                        Submit
                    </button>
                </div>
                <button onClick={closeAddContentModal} className="text-gray-500">Cancel</button>
            </div>
        </div>
    );
};

export default AddContentModal;