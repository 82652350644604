import openai from '../../config/openaiConfig';
import { useState, useEffect } from 'react';

const AssistantSettingsPage = ({
    assistantId,
    assistantVectorStoreId
}    
) => {
    const [assistant, setAssistant] = useState(null);

    const fetchAssistant = async () => {
        const myAssistant = await openai.beta.assistants.retrieve(assistantId);
        setAssistant(myAssistant);
    };

    useEffect(() => {
        fetchAssistant();
    }, [assistantId]);

    return (    
        <div className='flex flex-col justify-center p-12'>
            <p className="text-3xl mt-10 font-bold text-left">Assistant Settings</p>                 
            {assistant && (
                <div className="text-left w-full max-w-4xl">
                    {/* Heading Section */}
                    <div className='mt-12'>
                        <div><strong>Name:</strong> {assistant.name}</div>
                        <div><strong>Created At:</strong> {new Date(assistant.created_at * 1000).toLocaleString()}</div>
                        <div><strong>Instructions:</strong> {assistant.instructions}</div>
                    </div>                    
                    
                    {/* Debugging Section */}
                    <div className='mt-12 italic text-gray-500 text-sm'>
                        <p>For debugging only:</p>
                        <div>ID: {assistant.id}</div>
                        <div>Model: {assistant.model}</div>
                        <div>Temperature: {assistant.temperature}</div>
                        <div>Response Format: {assistant.response_format}</div>
                        <div>Tools: {assistant.tools.map(tool => tool.type).join(', ')}</div>
                        <div>Top P: {assistant.top_p}</div>
                        <div>Vector Store ID: {assistantVectorStoreId}</div>                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default AssistantSettingsPage;