import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { collection, getDocs, query, where, orderBy, } from "firebase/firestore";
import db from '../../config/firebaseConfig';
import './OrganizationProfile.css';

const OrganizationProfile = ({        
    selectedOrganizationEin,
    setSelectedOpportunity,
    setSelectedOrganizationEin,
    setShowLoadingModal,
    setShowOpportunityAndFunderModal,    
}) => {
    // State variables with parent state objects
    const [organizationObjectFromFirestore, setOrganizationObjectFromFirestore] = useState(null);
    const [latestFilingObjectFromFirestore, setLatestFilingObjectFromFirestore] = useState(null);

    // State variables with organization details
    const [selectedOrganizationName, setSelectedOrganizationName] = useState(null);
    const [selectedOrganizationAddress, setSelectedOrganizationAddress] = useState(null);
    const [latestAssetsBOY, setLatestAssetsBOY] = useState(null);
    const [latestAssetsEOY, setLatestAssetsEOY] = useState(null);
    const [latestRevenue, setLatestRevenue] = useState(null);
    const [latestExpenses, setLatestExpenses] = useState(null);
    const [awardsReceived, setAwardsReceived] = useState([]);
    const [awardsGranted, setAwardsGranted] = useState([]);
    const [keyPeople, setKeyPeople] = useState([]);
    
    // Component Functions
    const handleOrganizationCellClicked = (params) => {
        
        // Clear prior selected opportunity
        setSelectedOpportunity(null)
        setSelectedOrganizationEin(null)

        const { colDef, data } = params;
        let einOfClickedOrganization = '';

        if (colDef.field === 'FilerObject.FilerGeneralDetails.BusinessName.Line1') {
            einOfClickedOrganization = data.FilerObject.FilerGeneralDetails.Ein;
        } else if (colDef.field === 'RecipientDetails.RecipientBusinessName') {
            einOfClickedOrganization = data.AwardDetails.RecipientEIN;
        } else if (colDef.field === 'funderName') { 
            einOfClickedOrganization = data.funderEIN;
        }

        if (einOfClickedOrganization === selectedOrganizationEin) {
            setShowOpportunityAndFunderModal(true);
        }
        
        if (einOfClickedOrganization && einOfClickedOrganization !== selectedOrganizationEin) {
            setShowOpportunityAndFunderModal(false)
            setSelectedOrganizationEin(einOfClickedOrganization)
        }
    };

    // If no Firestore object, then populate profile page with info from grant awards
    useEffect(() => {
        if (awardsReceived.length > 0 && organizationObjectFromFirestore === null && latestFilingObjectFromFirestore === null) {
            const recipientName = awardsReceived[0].RecipientDetails.RecipientBusinessName;
            setSelectedOrganizationName(recipientName);            
            const recipientDetails = awardsReceived[0].RecipientDetails;
            setSelectedOrganizationAddress(`${recipientDetails.AddressLine1}, ${recipientDetails.City}, ${recipientDetails.State} ${recipientDetails.ZIP}`);
        }
    }, [awardsReceived, organizationObjectFromFirestore, latestFilingObjectFromFirestore]);
    
    const fetchAwardsGranted = async (selectedOrganizationEin) => {
        try {
            console.log("Fetching awards from Firestore.")
            const q = query(collection(db, "990Awards"), where("FilerObject.FilerGeneralDetails.Ein", "==", selectedOrganizationEin));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                console.log("No matching awards found.");
                return null;
            }
            const awards = querySnapshot.docs.map(doc => doc.data());
            setAwardsGranted(awards);
        } catch (error) {
            console.error("Error fetching awards:", error);
        }    
    }

    const fetchAwardsReceived = async (selectedOrganizationEin) => {
        console.log("Fetching awards received by ", selectedOrganizationEin, "from Firestore.")
        try {
            const q = query(collection(db, "990Awards"), where("AwardDetails.RecipientEIN", "==", selectedOrganizationEin));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                console.log("No matching awards found.");
                return null;
            }
            const awards = querySnapshot.docs.map(doc => doc.data());
            // console.log("Awards received: ", awards);
            setAwardsReceived(awards);
        } catch (error) {
            console.error("Error fetching awards:", error);
        }    
    }

    useEffect(() => {
        if (latestFilingObjectFromFirestore) {            
            setLatestAssetsBOY(latestFilingObjectFromFirestore.FinancialDetails?.NetAssetsBOY || null);
            setLatestAssetsEOY(latestFilingObjectFromFirestore.FinancialDetails?.NetAssetsEOY || null);
            setLatestRevenue(latestFilingObjectFromFirestore.FinancialDetails?.TotalRevenue || null);
            setLatestExpenses(latestFilingObjectFromFirestore.FinancialDetails?.TotalExpenses || null);
            setKeyPeople(latestFilingObjectFromFirestore.OfficerDetails || []);
        }
    }, [latestFilingObjectFromFirestore]);

    
    
    useEffect(() => {
        if (selectedOrganizationEin) {
            console.log("Loading started")
            setShowLoadingModal(true)

            Promise.all([
                fetchGrantOrganizationByEIN(selectedOrganizationEin),
                fetchLatest990(selectedOrganizationEin),
                fetchAwardsReceived(selectedOrganizationEin),
                fetchAwardsGranted(selectedOrganizationEin)
            ]).then(([organization, latestFiling]) => {
                setOrganizationObjectFromFirestore(organization);
                setLatestFilingObjectFromFirestore(latestFiling);
                setShowLoadingModal(false);
            }).catch(error => {
                console.error("Error loading data:", error);
                setShowLoadingModal(false);
            });
        }
    }, [selectedOrganizationEin]);

    useEffect(() => {
        if (organizationObjectFromFirestore) {
            setSelectedOrganizationName(organizationObjectFromFirestore?.FilerGeneralDetails?.BusinessName?.Line1 || null);
            setSelectedOrganizationAddress(organizationObjectFromFirestore?.FilerGeneralDetails?.Address ? 
                `${organizationObjectFromFirestore.FilerGeneralDetails.Address.AddressLine1}, ${organizationObjectFromFirestore.FilerGeneralDetails.Address.City}, ${organizationObjectFromFirestore.FilerGeneralDetails.Address.State} ${organizationObjectFromFirestore.FilerGeneralDetails.Address.Zip}` 
                : 'N/A');
        }
    }, [organizationObjectFromFirestore]);

    const fetchGrantOrganizationByEIN = async (selectedOrganizationEin) => {
        if (!selectedOrganizationEin) {
            console.log("fetchGrantOrganizationByEIN() called, but selectedOrganizationEin not set. Skipping fetch.");
            return null;
        }
                
        try {
            console.log("Fetching 990FilerOrganization from Firestore.");
            const q = query(collection(db, "990FilerOrganizations"), where("FilerGeneralDetails.Ein", "==", selectedOrganizationEin));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                console.log("No matching grant organization found.");
                return null;
            }
            const organization = querySnapshot.docs[0].data();
            return organization;
        } catch (error) {
            console.error("Error fetching grant organization:", error);
            return null;
        }
    };

    const fetchLatest990 = async (selectedOrganizationEin) => {        
        try {
            console.log("Fetching latest 990 from Firestore for given FilerEin.")
            const q = query(collection(db, "990Forms"), where("FilerEin", "==", selectedOrganizationEin), orderBy("FilingDetails.TaxYr", "desc"));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                console.log("No matching 990s found.");
                return null;
            }
            const latestFiling = querySnapshot.docs[0].data();
            return latestFiling;
        } catch (error) {
            console.error("Error fetching latest 990:", error);
            return null;
        }    
    }
    
    // Define the column definitions for awardsReceived
    const awardsReceivedColumnDefs = [
        { 
            headerName: "Tax Year", 
            field: "FilingDetails.TaxYr",
            width:100
        },
        { 
            headerName: "Funder Name", 
            field: "FilerObject.FilerGeneralDetails.BusinessName.Line1",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : '',
            cellStyle: { color: '#4338CA', fontWeight: 'bold'} 
        },
        { 
            headerName: "Recipient Name", 
            field: "RecipientDetails.RecipientBusinessName",
            filter: "agTextColumnFilter",
            valueFormatter: ({ value }) => value ? value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : '',
            cellStyle: { color: '#4338CA', fontWeight: 'bold'} 
        },
        { 
            headerName: "Amount", 
            field: "AwardDetails.CashGrantAmt", 
            valueGetter: ({ data }) => data.AwardDetails ? parseFloat(data.AwardDetails.CashGrantAmt) : 0, // Ensure sorting uses numeric values
            valueFormatter: ({ value }) => `$${value.toLocaleString()}`,
            filter: "agNumberColumnFilter"
        },
        { 
            headerName: "Purpose", 
            field: "AwardDetails.PurposeOfGrantTxt",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Recipient City", 
            field: "RecipientDetails.City",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Recipient State", 
            field: "RecipientDetails.State",
            filter: "agTextColumnFilter",            
        },
        { 
            headerName: "Recipient ZIP", 
            field: "RecipientDetails.ZIP",
            filter: "agTextColumnFilter",            
        },

    ]
    
    const keyPeopleColDefs = [
        { headerName: "Name", field: "Name", flex: 1 },
        { headerName: "Title", field: "Title", flex: 1 },
        { headerName: "Average Hours Per Week", field: "AverageHoursPerWeek", flex: 1 },
        { headerName: "Compensation", field: "Compensation", valueFormatter: ({ value }) => `$${parseFloat(value).toLocaleString()}`, flex: 1 }
    ];

    return (
        <div className='text-left p-12 text-sm' >
            {selectedOrganizationName ? <p className='text-4xl font-bold'>{selectedOrganizationName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</p> : <p className='text-4xl font-bold'>Unknown Funder</p>}
            <div className="mt-3 flex justify-start items-center space-x-4">
                {awardsReceived.length > 0 && <div className="bg-green-200 text-green-900 p-2 rounded-lg text-sm">Grant Winner</div>}
                {awardsGranted.length > 0 && <div className="bg-blue-200 text-blue-800 p-2 rounded-lg text-sm">Grant Maker</div>}

            </div>

            <h3 className="orgDetailsLabel">General Details</h3>
            <div className="bg-gray-100 p-4 rounded-md mb-4">
                <table className="w-full border-collapse">
                    <tbody>
                        {organizationObjectFromFirestore?.FilerGeneralDetails?.WebsiteAddressTxt && (
                            <tr className="border-b border-gray-300">
                                <td className="py-2 px-4">Website</td>
                                <td className="text-right py-2 px-4">
                                    <a href={`http://${organizationObjectFromFirestore.FilerGeneralDetails.WebsiteAddressTxt}`} target="_blank" rel="noopener noreferrer" className="underline">{organizationObjectFromFirestore.FilerGeneralDetails.WebsiteAddressTxt}</a>
                                </td>
                            </tr>
                        )}
                        <tr className="border-b border-gray-300">
                            <td className="py-2 px-4">Address</td>
                            <td className="text-right py-2 px-4">
                                {selectedOrganizationAddress}
                            </td>
                        </tr>
                        {organizationObjectFromFirestore?.FilerGeneralDetails?.PhoneNum && (
                            <tr className="border-b border-gray-300">
                                <td className="py-2 px-4">Phone Number</td>
                                <td className="text-right py-2 px-4">
                                    {organizationObjectFromFirestore.FilerGeneralDetails.PhoneNum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                                </td>
                            </tr>
                        )}                  
                        <tr className="border-b border-gray-300">
                            <td className="py-2 px-4">EIN</td>
                            <td className="text-right py-2 px-4">
                                {selectedOrganizationEin}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            {(organizationObjectFromFirestore?.FilerGeneralDetails?.MissionDesc ||
            organizationObjectFromFirestore?.FilerGeneralDetails?.Desc ||
            organizationObjectFromFirestore?.FilerGeneralDetails?.ActivityOrMissionDesc) && (
                <>
                    <h3 className="orgDetailsLabel">Mission / Description</h3>
                    <div className="bg-gray-100 p-4 rounded-md mb-4">
                        <table className="w-full border-collapse">
                            <tbody>
                                {organizationObjectFromFirestore?.FilerGeneralDetails?.MissionDesc && (
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4">Mission</td>
                                        <td className="text-right py-2 px-4">{organizationObjectFromFirestore.FilerGeneralDetails.MissionDesc}</td>
                                    </tr>
                                )}
                                {organizationObjectFromFirestore?.FilerGeneralDetails?.Desc && (
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4">Description</td>
                                        <td className="text-right py-2 px-4">{organizationObjectFromFirestore.FilerGeneralDetails.Desc}</td>
                                    </tr>
                                )}
                                {organizationObjectFromFirestore?.FilerGeneralDetails?.ActivityOrMissionDesc && (
                                    <tr className="border-b border-gray-300">
                                        <td className="py-2 px-4">Activity or Mission Description</td>
                                        <td className="text-right py-2 px-4">{organizationObjectFromFirestore.FilerGeneralDetails.ActivityOrMissionDesc}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            { (latestAssetsBOY || latestAssetsEOY || latestRevenue || latestExpenses) && (
                <>
                    <h3 className="orgDetailsLabel">Financial Details</h3>
                    <div className="bg-gray-100 p-4 rounded-md mb-4">
                        <table className="w-full border-collapse">
                            <tbody>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Total Assets (Beginning of Year)</td>
                                    <td className="text-right py-2 px-4">${latestAssetsBOY ? latestAssetsBOY.toLocaleString() : 'N/A'}</td>
                                </tr>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Total Assets (End of Year)</td>
                                    <td className="text-right py-2 px-4">${latestAssetsEOY ? latestAssetsEOY.toLocaleString() : 'N/A'}</td>
                                </tr>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Growth Rate in Assets (YoY)</td>
                                    <td className="text-right py-2 px-4">
                                        {latestAssetsBOY && latestAssetsEOY ? `${((latestAssetsEOY - latestAssetsBOY) / latestAssetsBOY * 100).toFixed(2)}%` : 'N/A'}
                                    </td>
                                </tr>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Latest Revenue</td>
                                    <td className="text-right py-2 px-4">${latestRevenue ? latestRevenue.toLocaleString() : 'N/A'}</td>
                                </tr>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Latest Expenses</td>
                                    <td className="text-right py-2 px-4">${latestExpenses ? latestExpenses.toLocaleString() : 'N/A'}</td>
                                </tr>                        
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {keyPeople && keyPeople.length > 0 && (
                <>
                    <h3 className="orgDetailsLabel">Key People</h3>
                    <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
                        <AgGridReact
                            rowData={keyPeople}
                            columnDefs={keyPeopleColDefs}
                        />
                    </div>
                </>
            )}

            
            
            {awardsGranted.length > 0 && (
                <>
                    <h3 className="orgDetailsLabel">Giving History</h3>
                    <div className="bg-gray-100 p-4 rounded-md mb-4">
                        <table className="w-full border-collapse">
                            <tbody>                       
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Grants Given ($)</td>                                    
                                    <td className="text-right py-2 px-4">
                                        ${awardsGranted.reduce((sum, award) => sum + parseFloat(award.AwardDetails.CashGrantAmt || 0), 0).toLocaleString()}
                                    </td>
                                </tr>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Grants Given (#)</td>
                                    <td className="text-right py-2 px-4">
                                        {awardsGranted.length.toLocaleString()}
                                    </td>
                                </tr>
                                <tr className="border-b border-gray-300">
                                    <td className="py-2 px-4">Average Grant Size</td>
                                    <td className="text-right py-2 px-4">
                                        ${(Math.round(awardsGranted.reduce((sum, award) => sum + parseFloat(award.AwardDetails.CashGrantAmt || 0), 0) / awardsGranted.length)).toLocaleString()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            <h3 className="orgDetailsLabel">Awards Granted ({awardsGranted.length.toLocaleString()})</h3>
            {awardsGranted.length > 0 ? (
                <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
                    <AgGridReact
                        rowData={awardsGranted}
                        columnDefs={awardsReceivedColumnDefs}
                        onCellClicked={handleOrganizationCellClicked}
                    />
                </div>
            ) : (
                <p>No awards granted available.</p>
            )}

            <h3 className="orgDetailsLabel">Awards Received ({awardsReceived.length})</h3>
            {awardsReceived.length > 0 ? (
                <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
                    <AgGridReact
                        rowData={awardsReceived}
                        columnDefs={awardsReceivedColumnDefs}
                        onCellClicked={handleOrganizationCellClicked}
                    />
                </div>
            ) : (
                <p>No awards received available.</p>
            )}


        </div>
    );
};

export default OrganizationProfile;