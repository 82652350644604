import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../AuthContext';
import { Link } from 'react-router-dom';
// import downCarrotBlack from '@down-carrot-black.png';
import downArrow from '../assets/down-arrow.png';
import plusIcon from '../assets/plus.png'



const SideNavBar = ({ 
    currentClientOrganization,
    organizationPrograms,
    setCurrentProgram,
    setCurrentView, 
    setProgramToModify,
    setShowClientOrganizationsPickerModal,
    setShowCreateProgramModal,
    setShowKnowledgeBaseModal,    
}) => {

    const { authUser, handleSignOut } = useContext(AuthContext);    

    useEffect(() => {
        console.log("Organization Programs:", organizationPrograms);
    }, [organizationPrograms]);

    const handleOrganizationButtonClicked = () => {
        setShowClientOrganizationsPickerModal(true)
    };
    
    const handleCreateProgramButtonClicked = () => {
        console.log("Create program")
        setProgramToModify(null)
        setShowCreateProgramModal(true)
    };

    const handleProgramSelected = (program) => {
        console.log("Program selected: ", program)
        setCurrentProgram(program)
        setCurrentView('programDashboard')
    };
    
    return (
        <div className='bg-gray-300 p-6 text-left' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '300px', height: '100vh' }}>
            <button className='text-2xl mt-6' style={{fontStyle: 'cursive', fontWeight: '900'}} onClick={() => setCurrentView('homeChat')}>🪄 GrantMagic</button>
            <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Change-Log-30bcd234d85542a5bd30ca73f2d9fcb9?pvs=4" className='italic text-gray-700' target="_blank" rel="noopener noreferrer">Version 0.3.0 (Beta)</a>
            {authUser ? (
                <div className='w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>                   
                    
                    {/* Current Org Button */}
                    {currentClientOrganization && 
                        <button onClick={handleOrganizationButtonClicked} className='p-2 rounded-lg w-full hover:bg-gray-200 flex items-center mt-7'>
                            <div className='bg-gray-500 rounded-lg text-xs py-1 px-2'>{currentClientOrganization.name[0].toUpperCase()}</div>
                            <h2 className="font-bold ml-2">{currentClientOrganization.name}</h2>
                            <img src={downArrow} alt="down-carrot-black" style={{width: '20px', height: '20px', marginLeft: 'auto'}} />
                        </button>
                    }

                    <div className='flex space-x-2 mt-7'>
                        <h2 className="font-bold text-xl">Programs</h2>
                        <button className='bg-gray-600 rounded-lg mt-1' style={{width: '19px', height: '19px'}} onClick={handleCreateProgramButtonClicked} >
                            <img className='p-1' src={plusIcon} alt="plusIcon"/>
                        </button> 
                        
                    </div>
                    
                    
                    {organizationPrograms && organizationPrograms.length > 0 && organizationPrograms.map(program => (
                        <button key={program.id} onClick={() => handleProgramSelected(program)} className='mt-2 py-1 rounded-lg w-full hover:bg-gray-200 hover:ml-1 hover:pl-2 flex items-center'>
                            <h3 className="text-left text-sm">{program.programName}</h3>
                        </button>
                    ))}

                    {/* <button onClick={handleCreateProgramButtonClicked} className='mt-5 p-2 text-center rounded-lg w-full bg-gray-400 flex items-center justify-center text-white font-medium'>
                        <p>Create Program (shrink)</p>
                    </button> */}

                    
                    <div className="w-full mt-8 text-xl">
                        <button className="font-bold w-full mb-3 text-left" onClick={() => setCurrentView('awardsDatabase')}>Funder Search</button>
                        <button className='font-bold w-full mb-3 text-left' onClick={() => setCurrentView('proposalsDashboard')}>Proposals</button>
                        <button className='font-bold w-full mb-3 text-left' onClick={() => setShowKnowledgeBaseModal(true)}>Assistant</button>
                        {/* <button className='font-bold w-full text-left' onClick={() => setCurrentView('assistantSettings')}>Assistant</button> */}
                    </div>
                    {/* <h2 className="font-bold mt-10">Discover Grants</h2> */}
                    
                    {/* <button onClick={() => setCurrentView('opportunitiesDatabase')}>Active Opportunities</button> */}
                    {/* <h2 className="font-bold mt-10">Write Grants</h2> */}
                    
                    {/* <h2 className="font-bold mt-10">Manage AI Assistant</h2> */}
                    {/* <button onClick={() => setCurrentView('organizationDetails')}>Organization Details</button> */}                    
                    
                    
                    {/* <h2 className="font-bold mt-10">Membership</h2> */}
                    {/* <button onClick={() => setCurrentView('manageMembership')}>Manage Membership (MOVE ACCOUNT)</button> */}
                    {/* <button onClick={() => setCurrentView('getFreeGrantMagic')}>Get Free Premium Access (MOVE ACCOUNT)</button> */}
                    {/* <h2 className="font-bold mt-10">Settings</h2> */}
                    
                    {/* <a href="/referrals" className="">Refer a Friend</a> */}
                    <div className='w-full flex flex-col text-md mt-6 text-gray-700 '>
                        <button className='mb-1 text-left hover:text-gray-900' onClick={() => setCurrentView('accountSettings')}>Account</button>
                        <a className='mb-1 hover:text-gray-900' href="https://grantmagic.featurebase.app/"  target="_blank" rel="noopener noreferrer">Feature Requests</a>                    
                        <a className='mb-1 hover:text-gray-900' href="https://grantmagic.featurebase.app/roadmap" target="_blank" rel="noopener noreferrer">Product Roadmap</a>                                        
                        <a className='mb-1 hover:text-gray-900' href="https://tally.so/r/mDVa5j" target="_blank" rel="noopener noreferrer">Support</a>                    
                        {/* <a href="/referrals" className="">Manage Membership</a> */}
                        <a className='mb-1 hover:text-gray-900' href="#" onClick={handleSignOut} >Logout</a>    
                    </div>
                    
                </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h2 className="font-bold mt-10">Settings</h2>
                    <a href="https://tally.so/r/mDVa5j" className="" target="_blank" rel="noopener noreferrer">Support</a>
                    <Link to="/signup" className="">Sign Up</Link>          
                    <Link to="/login" className="">Log In</Link>
                </div>
            )}
        </div>
    );
};

export default SideNavBar;
