import React from 'react';

function PaywallComponent({
    membershipDetails
}) {
  
    return (
        <div className='flex flex-col justify-center bg-orange-100 mt-6 p-12 rounded-lg'>
            <p className="text-3xl font-bold text-left">Premium Plan Only</p>                 
            <div className='text-left mt-10'>
                <p className='mb-5'>You need a Premium plan to access this functionality.</p>
                <a href="https://satodadj.gumroad.com/l/rlqzat" target="_blank" rel="noopener noreferrer" className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-3 px-4 rounded">
                    Upgrade Now
                </a>                
            </div>
        </div>                    
  );
}

export default PaywallComponent;