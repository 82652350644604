    
    import { doc, updateDoc } from "firebase/firestore";
    import db from '../../config/firebaseConfig';

    const ManageProposalModal = ({             
            currentProposalToManage,
            refreshGrantProposals,
            setShowManageProposalModal
        }) => {

        const handleProposalUpdate = async () => {
            const name = document.getElementById('proposal-name').value;
            const status = document.getElementById('proposal-status').value;
            const notes = document.getElementById('proposal-notes').value;

            try {
                console.log("updating Grant Proposal document in Firestore")
                const proposalRef = doc(db, 'grantProposals', currentProposalToManage.id);
                await updateDoc(proposalRef, {
                    name: name,
                    status: status,
                    notes: notes
                });
                console.log('Proposal updated successfully');
            } catch (error) {
                console.error('Error updating proposal: ', error);
            }

            refreshGrantProposals()
            setShowManageProposalModal(false)
        };

        return (
            <div className="bg-gray-900 bg-opacity-50" 
                style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={() => setShowManageProposalModal(false)}
            >
            
                {/* Modal */}
                <div className='text-left bg-white rounded-lg p-12' 
                 style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
                 onClick={(e) => e.stopPropagation()}
                 >
                
                    <p className='text-2xl text-center'>Manage Proposal</p>
                    

                    <div className='mt-6'>
                        <p className='font-bold text-gray-500 mb-1'>NAME</p>
                        <input id="proposal-name" type="text" defaultValue={currentProposalToManage.name} />
                    </div>

                    <div className='mt-6'>
                    <p className='font-bold text-gray-500 mb-1'>STATUS</p>
                        <select id="proposal-status" defaultValue={currentProposalToManage.status} className="mt-1 border border-gray-300 rounded-md shadow-sm p-1">
                            <option value="In Progress">In Progress</option>
                            <option value="Complete">Complete</option>
                            <option value="Saved">Saved</option>
                        </select>
                    </div>

                    <div className='mt-6'>
                        <p className='font-bold text-gray-500 mb-1'>NOTES</p>
                        <input id="proposal-notes" type="text" defaultValue={currentProposalToManage.notes} />
                    </div>

                    <div className="mt-4 space-x-4">
                        <button onClick={handleProposalUpdate} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Update</button>
                        <button onClick={() => setShowManageProposalModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>                    
                    </div>
                    

                    
                </div>
            </div>
        );
    };

    export default ManageProposalModal;