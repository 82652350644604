import React, { useEffect } from 'react';

function GetFreeGrantMagic({
    membershipDetails
}) {
  
    return (
        <div className='flex flex-col justify-center p-12'>
            <p className="text-3xl mt-10 font-bold text-left">Get Free Grant Magic</p>                 
            <div className='text-left mt-10'>
                <div>
                    Want access to our Grant Database and Grant Discovery features? But don't have enough budget? No worries at all. We offer two ways to get free access to Grant Magic:
                    <ul className="list-disc ml-5 mt-2">
                        <li>
                            <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Referral-Program-ce8129a79f1445e0a32f25867ac94b01?pvs=4" className="text-indigo-500 underline" target="_blank" rel="noopener noreferrer">Refer a friend</a>
                        </li>
                        <li>
                            <a href="https://ossified-pluto-bdd.notion.site/GrantMagic-Case-Study-Program-c9964ca155424795a416baf59b893b5c?pvs=4" className="text-indigo-500 underline" target="_blank" rel="noopener noreferrer">Join our case study program</a>
                        </li>
                    </ul>
                </div>                
            </div>
        </div>                    
  );
}

export default GetFreeGrantMagic;