import openai from '../config/openaiConfig';

export const createVectorStore = async () => {
    try {
        console.log("Creating vector store.");
        const createdVectorStore = await openai.beta.vectorStores.create({
            expires_after: {
                anchor: "last_active_at",
                days: 365
            }
        });
        console.log("Vector store created successfully:", createdVectorStore);
        return createdVectorStore;
    } catch (error) {
        console.error("Failed to create vector store:", error);
        throw error;
    }
};

export const createAssistantInOpenAi = async (organizationName, vectorStoreId) => {
    if (organizationName) {
        try {
            console.log("Creating assistant.");
            const myAssistant = await openai.beta.assistants.create({
                instructions: `You are a grant writer employed by a nonprofit called ${organizationName}. You write personalized responses to grant applications by adapting content from your knowledge base, so your answers sound as if they were written by nonprofit themself.`,
                name: `Daisy`,
                model: "gpt-4o",
                temperature: 0.5,
                tools: [{"type": "file_search"}],
                tool_resources: {
                    "file_search": {
                        "vector_store_ids": [vectorStoreId]
                    }
                }
            });

            console.log("Assistant created successfully:", myAssistant);
            return myAssistant;
        } catch (error) {
            console.error("Failed to create assistant:", error);
            throw error;
        }
    } else {
        console.log("Assistant name or organization name is not set. Skipping assistant creation.");
        throw new Error("Assistant name or organization name is not set.");
    }
};


export const createThreadInOpenAi = async () => {
    const thread = await openai.beta.threads.create();
    console.log("Thread created: ", thread);
    return thread;
};

export const createRunForThreadInOpenAi = async (threadId, assistantId) => {
    try {
        return await openai.beta.threads.runs.create(threadId, { assistant_id: assistantId });
    } catch (error) {
        console.error("Failed to create run for thread with ID:", threadId, error);
        throw error;
    }
};
    
export const fetchFilesFromOpenAI = async (assistantVectorStoreId) => {
    console.log(`Fetching files for vector store ${assistantVectorStoreId} from OpenAI`)
    const vectorStoreFilesResponse = await openai.beta.vectorStores.files.list(assistantVectorStoreId);
    return vectorStoreFilesResponse.data;
};

export const getThreadMessagesFromOpenAi = async (threadId) => {
    console.log("Running getThreadMessagesFromOpenAi");
    const threadMessagesResponse = await openai.beta.threads.messages.list(threadId, { order: 'asc', limit: '100' });
    const threadMessages = threadMessagesResponse.data;

    // Process each message to handle annotations
    const processedMessages = threadMessages.map((message) => {
        if (message.content[0].text.annotations) {
            let messageContent = message.content[0].text.value;
            const annotations = message.content[0].text.annotations;

            annotations.forEach((annotation, index) => {
                messageContent = messageContent.replace(annotation.text, ` [${index}]`);
            });

            message.content[0].text.value = messageContent;
        }
        return message;
    });

    return processedMessages;
};

export const addMessageToExistingThreadInOpenAi = async (threadId, userTypedInput, fileAddedToChat) => {
    const messagePayload = {
        role: "user",
        content: userTypedInput,
        attachments: fileAddedToChat ? [{ file_id: fileAddedToChat.id, tools: [{ type: "file_search" }] }] : undefined,
    };

    const message = await openai.beta.threads.messages.create(threadId, messagePayload);
    console.log("Successfully added user message to thread with ID:", threadId);
    return message;
};

export const deleteFileFromOpenAiVectorStore = async (assistantVectorStoreId, fileId) => {
    try {
        // Delete the file from the assistant's vector store
        const deletedVectorStoreFile = await openai.beta.vectorStores.files.del(
            assistantVectorStoreId,
            fileId
        );
        console.log("Deleted vector store file: ", deletedVectorStoreFile);
    } catch (error) {
        console.error("Failed to delete file from OpenAI: ", error);
        throw error;
    }
};

export const checkRunStatusInOpenAi = async (threadId, runId) => {
    try {
        const runStatus = await openai.beta.threads.runs.retrieve(threadId, runId);
        console.log("Run status: ", runStatus);
        return runStatus;
    } catch (error) {
        console.error("Error checking run status:", error);
        throw error;
    }
};

export const addMessageToThreadInOpenAi = async (threadId, userTypedInput, fileAddedToChat) => {
    console.log("Adding user message to thread with ID:", threadId);
    const completeMessagePayload = {
        role: "user",
        content: userTypedInput,
        attachments: fileAddedToChat 
            ? [{ file_id: fileAddedToChat.id, tools: [{ type: "file_search" }] }] 
            : undefined,
    };

    console.log("Complete message payload: ", completeMessagePayload)

    try {
        await openai.beta.threads.messages.create(threadId, completeMessagePayload);
        console.log("Successfully added user message to thread with ID:", threadId);
    } catch (error) {
        console.error("Failed to add user message to thread with ID:", threadId, error);
        throw error;
    }
};

export const uploadFileToOpenAI = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'assistants');
    console.log("Uploading file to OpenAI");
    const response = await fetch('https://api.openai.com/v1/files', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${openai.apiKey}`
        },
        body: formData
    });

    const result = await response.json();
    console.log("File uploaded to OpenAI, file ID:", result.id);
    return result.id;
};

export const addFileToAssistantVectorStore = async (assistantVectorStoreId, openaiFileId) => {
    if (openai.beta.vectorStores && openai.beta.vectorStores.files) {
        console.log("Adding file to Assistant's vector store");
        const myVectorStoreFile = await openai.beta.vectorStores.files.create(
            assistantVectorStoreId,
            {
                file_id: openaiFileId
            }
        );
        console.log("File added to Assistant's vector store:", myVectorStoreFile);
        return myVectorStoreFile;
    } else {
        throw new Error("The OpenAI client does not support vectorStores.files.create");
    }
};

export const getAnswerFromOpenAI = async (question, selectedFiles, assistantId, additionalInstructions) => {
    const messagePayload = {
        role: "user",
        content: 
            `
                Using your knowledge base and the attached files, answer concisely the following question: 
                ${question}. 
                ${additionalInstructions}
            `,
        // attachments: selectedFiles.length > 0 ? selectedFiles.map(file_id => ({ file_id, tools: [{ type: "file_search" }] })) : undefined,
        attachments: selectedFiles.length > 0 ? selectedFiles.map(file => ({ file_id: file.id, tools: [{ type: "file_search" }] })) : undefined,
    };

    const run = await openai.beta.threads.createAndRun({
        assistant_id: assistantId,
        model: "gpt-3.5-turbo",
        temperature: 0.4, 
        thread: {
            messages: [messagePayload],
        },
    });

    if (!run || !run.thread_id) {
        throw new Error("Invalid response from OpenAI API: 'run' or 'run.thread_id' is undefined");
    }

    const runThreadId = run.thread_id;
    console.log("Run from getOpenAITextProposal: ", run);

    // Check the run status periodically
    const result = await checkRunStatusOnMagicDraft(runThreadId, run.id);
    return result;
};

// Function to check the run status periodically
export const checkRunStatusOnMagicDraft = async (runThreadId, runId) => {
    const maxRetries = 30; // Maximum number of retries
    const delay = 2000; // Delay between retries in milliseconds
    let retries = 0;

    while (retries < maxRetries) {
        const runStatus = await openai.beta.threads.runs.retrieve(runThreadId, runId);

        if (runStatus.status === 'completed') {
            console.log("Run completed, fetching messages.");
            // Once the run is completed, fetch the thread messages
            const messagesResponse = await openai.beta.threads.messages.list(runThreadId, { order: 'asc', limit: 100 });
            const messages = messagesResponse.data;

            if (messages && messages.length > 0) {
                console.log("Messages fetched successfully:", messages);
                return messages[1].content[0].text.value; // Assuming the second message contains the answer
            }
        } else if (runStatus.status === 'failed') {
            console.error("Run failed with error: ", runStatus.last_error.code, ". ", runStatus.last_error.message);
            return { error: runStatus.error }; // Return error details for appropriate error handling
        } else {
            console.log("Run still in progress, checking status again after a delay.");
            // If the run is still in progress, check again after a delay
            await new Promise(resolve => setTimeout(resolve, delay)); // Check every 2 seconds
            retries++;
        }
    }

    console.error("Run status check timed out.");
    return { error: "Run status check timed out." };
};

export const createAndRunThread = async (assistantId, messagePayload) => {
    const run = await openai.beta.threads.createAndRun({
        assistant_id: assistantId,
        thread: {
            messages: [messagePayload],
        },
    });

    if (!run || !run.thread_id) {
        throw new Error("Invalid response from OpenAI API: 'run' or 'run.thread_id' is undefined");
    }

    return run;
};

export const checkRunStatusOnInlineEditorProposal = async (runThreadId, runId) => {
    const maxRetries = 30; // Maximum number of retries
    const delay = 2000; // Delay between retries in milliseconds
    let retries = 0;

    while (retries < maxRetries) {
        const runStatus = await openai.beta.threads.runs.retrieve(runThreadId, runId);

        if (runStatus.status === 'completed') {
            console.log("Run completed, fetching messages.");
            // Once the run is completed, fetch the thread messages
            const messagesResponse = await openai.beta.threads.messages.list(runThreadId, { order: 'asc', limit: 100 });
            const messages = messagesResponse.data;

            if (messages && messages.length > 0) {
                console.log("Messages fetched successfully:", messages);
                return messages[1].content[0].text.value; // Assuming the first message contains the revised text
            }
        } else if (runStatus.status === 'failed') {
            console.error("Run failed with error: ", runStatus.last_error.code, ". ", runStatus.last_error.message);
            return { error: runStatus.error }; // Return error details for appropriate error handling
        } else {
            console.log("Run still in progress, checking status again after a delay.");
            // If the run is still in progress, check again after a delay
            await new Promise(resolve => setTimeout(resolve, delay)); // Check every 2 seconds
            retries++;
        }
    }    
    return { error: "Run status check timed out." };
};

