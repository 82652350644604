import React from 'react';
import GrantDatabaseSearch from './GrantDatabaseSearch'; 
import PaywallComponent from '../HelperComponents/PaywallComponent';

function AwardsDatabase({
    membershipDetails,
    selectedOrganizationEin,
    setSelectedOpportunity,
    setSelectedOrganizationEin,
    setShowLoadingModal,
    setShowOpportunityAndFunderModal,
}

) {    

    return (
        <div className="p-12">
            <p className="text-3xl mt-10 font-bold text-left">Grant Awards Database</p>                 
            <p className='italic text-left text-gray-500'>To discover funders who may be a good fit for your organization, search through our database of raw grant awards data from IRS 990 filings. </p>
            
            {/* Conditional Rendering based on membershipDetails */}
            {membershipDetails ? (
                <GrantDatabaseSearch 
                    setSelectedOrganizationEin={setSelectedOrganizationEin}
                    setShowLoadingModal={setShowLoadingModal}
                    selectedOrganizationEin={selectedOrganizationEin}
                    setSelectedOpportunity={setSelectedOpportunity}
                    setShowOpportunityAndFunderModal={setShowOpportunityAndFunderModal}
                />
            ) : (
                <PaywallComponent />
            )}
        </div>
    );
}

export default AwardsDatabase;