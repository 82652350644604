import React from 'react';

const MagicFirstDraftModal = ({ 
    generateMagicFirstDraft, 
    refreshGrantProposals,
    setShowMagicFirstDraftModal    
}) => {
            
    const handleGenerateButtonClicked = async () => {
        const applicationInstructions = document.getElementById('applicationInstructions').value;
        if (!applicationInstructions) {
            alert('Please provide application instructions.');
            return;
        }
        await generateMagicFirstDraft(applicationInstructions, "Untitled"); // Ensure this completes first
        refreshGrantProposals();
        setShowMagicFirstDraftModal(false);
    }

    return (
        <div className="bg-gray-900 bg-opacity-50" 
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '9999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onClick={() => setShowMagicFirstDraftModal(false)}
    >
    
        {/* Modal */}
        <div className='text-left bg-white rounded-lg p-12' 
         style={{ width: '80vw', height: '80vh', overflowY: 'auto' }}
         onClick={(e) => e.stopPropagation()}
         >
                <p className='text-2xl text-left font-bold'>Magic First Draft</p> 
                <p><p className="text-left ml-1 mr-2 text-gray-500 italic">Paste in a few grant application questions, and Daisy will use your training data to instantly draft personalized responses</p></p>
                
                <div className='mt-6 text-left'>
                    <p className='font-bold text-sm text-gray-500'>APPLICATION INSTRUCTIONS</p>
                    <textarea id="applicationInstructions" className="border border-gray-300 rounded-lg p-2 mt-1 w-full h-40" placeholder="What is your mission? When were you founded? Tell us about your leadership team."></textarea>                    
                </div>                    

                <div className="mt-4 space-x-2">
                    <button onClick={handleGenerateButtonClicked} className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded">Generate</button>
                    <button onClick={() => setShowMagicFirstDraftModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancel</button>                                   
                </div>
                
            </div>
        </div>
    );
};

export default MagicFirstDraftModal;